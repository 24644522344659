import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "events-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpenGatesHeader = _resolveComponent("OpenGatesHeader")!
  const _component_TalkItem = _resolveComponent("TalkItem")!
  const _component_OpenGatesFooter = _resolveComponent("OpenGatesFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_OpenGatesHeader),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedTalks, (talk) => {
        return (_openBlock(), _createBlock(_component_TalkItem, {
          key: talk.id + talk.occupiedSeats,
          talk: talk,
          myTalks: _ctx.myTalks
        }, null, 8, ["talk", "myTalks"]))
      }), 128))
    ]),
    _createVNode(_component_OpenGatesFooter)
  ], 64))
}