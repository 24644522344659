import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "talk-item--card" }
const _hoisted_2 = { class: "talk-item--card--info-block" }
const _hoisted_3 = { class: "talk-item--card__time" }
const _hoisted_4 = { class: "talk-item--card--description-block" }
const _hoisted_5 = { class: "talk-item--card__title" }
const _hoisted_6 = { class: "talk-item--card__description" }
const _hoisted_7 = { class: "talk-item--card--sub-info" }
const _hoisted_8 = { class: "talk-item--card__badge" }
const _hoisted_9 = { class: "talk-item--card__badge" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createVNode(_component_fa, { icon: "clock" }),
        _createTextVNode(" " + _toDisplayString(_ctx.talk.time), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.talk.title), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(_ctx.shortDescription), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_fa, { icon: "fa-solid fa-location-dot" }),
          _createTextVNode(" Location: " + _toDisplayString(_ctx.talk.location), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_fa, { icon: "fa-solid fa-people-group" }),
          _createTextVNode(" " + _toDisplayString(_ctx.talk.occupiedSeats), 1)
        ])
      ])
    ])
  ]))
}