import { AXIOS_REQUEST } from "../../config";
import { ITalkItem, ITalkItemState } from "../../../interfaces/TalkItem";
import { ActionTree, MutationTree } from "vuex";
import { reactive, ref } from "vue";

const state = reactive({
  openItemId: null,
  talks: ref([] as ITalkItem[]),
  myTalks: ref([] as ITalkItem[]),
  ratingAverage: null,
});

const getters = {
  getTalks: (state: { talks: ITalkItemState["talks"] }) => {
    return state.talks.sort(
      (a, b) =>
        Number(a.time.substring(0, 2)) -
        Number(b.time.substring(0, 2) && a.title.localeCompare(b.title))
    );
  },
  getMyTalks: (state: { myTalks: ITalkItemState["myTalks"] }) => {
    return state.myTalks;
  },

  getRatingAverage(state: { ratingAverage: number | null }) {
    return state.ratingAverage;
  },

  getMyTalkIds: (state: { myTalkIds: number[] }) => {
    return state.myTalkIds;
  },
  getCurrentItem: (state: { openItemId: number | null }) => state.openItemId,
};

const mutations: MutationTree<ITalkItemState> = {
  setTalks(state: ITalkItemState, talks: ITalkItem[]) {
    state.talks = talks;
  },

  setMyTalks(state: ITalkItemState, talks: ITalkItem[]) {
    state.myTalks = talks;
  },

  setOpenItemId(state, itemId: number) {
    state.openItemId = itemId;
  },

  setRatingAverage(state, ratingAverage: number) {
    state.ratingAverage = ratingAverage;
  },

  clearOpenItemId(state) {
    state.openItemId = null;
  },
};

const actions: ActionTree<ITalkItemState, ITalkItemState> | any = {
  fetchTalks(context: any) {
    AXIOS_REQUEST("GET", "/talks")
      .then((response) => {
        const talksDO: ITalkItem[] = [];
        for (const id in response.data) {
          talksDO.push({
            id: id,
            ...response.data[id],
          });
        }

        context.commit("setTalks", talksDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  fetchAverageTalkRating(context: any, talkId: number) {
    AXIOS_REQUEST("GET", `/talks/${talkId}/ratings`)
      .then((response) => {
        context.commit("setRatingAverage", response.data);
        // return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  attendTalk(context: any, payload: any) {
    AXIOS_REQUEST(
      "POST",
      `/talks/${payload.talkId}/user/${payload.userId}/attend`
    )
      .then((response) => {
        const talksDO: any = [];
        for (const id in response.data) {
          talksDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setMyTalks", talksDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  fetchAttendedTalks(context: any, userId: number) {
    AXIOS_REQUEST("GET", `/users/${userId}/talks`)
      .then((response) => {
        const talksDO: any = [];
        const talksIds: any = [];
        for (const id in response.data) {
          talksDO.push({
            id: id,
            ...response.data[id],
          });
        }

        context.commit("setMyTalks", talksDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  openItem({ commit }: any, itemId: number) {
    commit("setOpenItemId", itemId);
  },

  closeItem({ commit }: any) {
    commit("clearOpenItemId");
  },
};

const talkModule = {
  state,
  getters,
  mutations,
  actions,
};

export default talkModule;
