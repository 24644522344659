import { AXIOS_REQUEST } from "../../config";
import { reactive, ref } from "vue";
import { IQuestion, IQuestionState } from "@/interfaces/Questions";

const state = reactive({});

const getters = {};

const mutations = {};

const actions: any = {
  addRating(context: any, payload: any) {
    AXIOS_REQUEST("POST", `/ratings`, {
      score: payload.score,
      talkId: payload.talkId,
      userId: payload.userId,
    }).catch((error) => {
      return error.response.status;
    });
  },
};

const ratingModule = {
  state,
  getters,
  mutations,
  actions,
};

export default ratingModule;
