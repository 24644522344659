import { AXIOS_REQUEST } from "../../config";
import { IUser, IUserState } from "../../../interfaces/User";
import { ActionTree, MutationTree } from "vuex";
import { reactive, ref } from "vue";

const state: IUserState = reactive({
  user: null,
});

const getters = {};

const mutations = {
  setUser(state: IUserState, user: IUser) {
    state.user = user;
  },
};

const actions: any = {
  registerUser(context: any, payload: IUser) {
    AXIOS_REQUEST("POST", "/users", payload)
      .then((response) => {
        const UserDO: IUser = response.data;
        localStorage.setItem("User", JSON.stringify(UserDO));
        context.commit("setUser", UserDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const registerModule = {
  state,
  getters,
  mutations,
  actions,
};

export default registerModule;
