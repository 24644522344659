
import { defineComponent, computed } from "vue";
import { ITalkItem } from "../../interfaces/TalkItem";
import "./talk-item.scss";

export default defineComponent({
  name: "TalkItemCard",
  props: {
    talk: { type: Object as () => ITalkItem, required: true },
  },
  setup(props) {
    const shortDescription = computed(() => {
      const screenWidth = window.innerWidth;
      let threshold = 55;

      if (screenWidth >= 440) {
        threshold = 80;
      } else if (screenWidth >= 768) {
        threshold = 90;
      }

      if (props.talk.description?.length <= threshold) {
        return props.talk.description?.replace(/<br>/g, "-");
      } else {
        return (
          props.talk.description?.replace(/<br>/g, "-").slice(0, threshold) +
          "..."
        );
      }
    });

    return { shortDescription, ...props };
  },
});
