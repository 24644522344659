
import { computed, defineComponent, onMounted, ref } from "vue";
import TalkItemCard from "./TalkItemCard.vue";
import TalkItemCardDetail from "./TalkItemCardDetail.vue";
import { ITalkItem } from "../../interfaces/TalkItem";
import { IUser } from "../../interfaces/User";
import "./talk-item.scss";
import { useStore } from "vuex";
import questionModule from "@/store/modules/questions";
import StarRating from "vue-star-rating";

export default defineComponent({
  name: "TalkItem",
  props: {
    talk: { type: Object as () => ITalkItem, required: true },
    myTalks: { type: Object as () => ITalkItem[], required: true },
  },
  components: {
    StarRating,
    TalkItemCard,
    TalkItemCardDetail,
  },
  setup(props) {
    const store = useStore();
    const user: IUser = JSON.parse(localStorage.getItem("User") || "");
    const formData = ref({
      question: "",
    });
    let errorMessage = ref("");
    let voting = ref("");

    const formDataRating = ref({
      rating: null,
    });

    const openItem = () => {
      if (props.talk.id === store.getters.getCurrentItem) {
        store.dispatch("closeItem");
        store.dispatch("fetchQuestions", props.talk.id);
      } else {
        store.dispatch("openItem", props.talk.id);
        store.dispatch("fetchQuestions", props.talk.id);
      }
    };

    const isCurrentOpen = computed(() => {
      return props.talk.id === store.getters.getCurrentItem;
    });

    const attendTalk = () => {
      store.dispatch("attendTalk", { talkId: props.talk.id, userId: user.id });
      location.reload();
    };

    const isAttended = () => {
      if (props.myTalks.find((item) => item.id === props.talk.id)) {
        return false;
      } else {
        return true;
      }
    };

    const setRating = (rating: any) => {
      store
        .dispatch("addRating", {
          score: rating,
          talkId: props.talk.id,
          userId: user.id,
        })
        .then(() => {
          errorMessage.value = "Only your first rating will be counted";
        });

      store.dispatch("fetchAverageTalkRating", props.talk.id);
      setTimeout(() => {
        voting.value = store.getters.getRatingAverage;
      }, 400);
    };

    onMounted(() => {
      isAttended();
    });

    const addQuestion = async () => {
      await store.dispatch("addQuestions", {
        question: formData.value.question,
        talkId: props.talk.id,
        userId: user.id,
      });
      setTimeout(() => {
        store.dispatch("fetchQuestions", props.talk.id);
        formData.value.question = "";
      }, 100);
    };

    return {
      isAttended,
      attendTalk,
      isCurrentOpen,
      openItem,
      addQuestion,
      formData,
      formDataRating,
      setRating,
      errorMessage,
      voting,
      ...props,
    };
  },
});
