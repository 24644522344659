import { AXIOS_REQUEST } from "../../config";
import { reactive, ref } from "vue";
import { IQuestion, IQuestionState } from "@/interfaces/Questions";

const state = reactive({
  questions: ref([] as IQuestion[]),
});

const getters = {
  getQuestions: (state: { questions: IQuestionState["questions"] }) => {
    return state.questions;
  },
};

const mutations = {
  setQuestions(state: IQuestionState, questions: IQuestion[]) {
    state.questions = questions;
  },
};

const actions: any = {
  fetchQuestions(context: any, talkId: number) {
    AXIOS_REQUEST("GET", `/questions/${talkId}`)
      .then((response) => {
        const questionsDO: IQuestion[] = [];
        for (const id in response.data) {
          questionsDO.push({
            id: id,
            ...response.data[id],
          });
        }

        context.commit("setQuestions", questionsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  addQuestions(context: any, payload: any) {
    AXIOS_REQUEST("POST", `/questions`, {
      content: payload.question,
      talkId: payload.talkId,
      userId: payload.userId,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const questionModule = {
  state,
  getters,
  mutations,
  actions,
};

export default questionModule;
