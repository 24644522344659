import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);
createApp(App)
  .component("fa", FontAwesomeIcon)
  .use(store)
  .use(router)
  .mount("#app");

const user = localStorage.getItem("User");

if (user) {
  // Leiten Sie den Benutzer zur Startseite weiter
  router.push({ name: "home" });
} else {
  // Zeigen Sie die Registrierungsseite an
  router.push({ name: "login" });
}
