
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import { ITalkItem } from "../../interfaces/TalkItem";
import "./talk-item.scss";
import { useStore } from "vuex";

export default defineComponent({
  name: "TalkItemCardDetail",
  props: {
    talk: { type: Object as () => ITalkItem, required: true },
    isFull: String,
  },
  setup(props) {
    const store = useStore();
    const questions = computed(() => {
      const questionsData = store.getters.getQuestions;
      return questionsData;
    });

    const showDetails = ref(false);

    return { showDetails, ...props, questions };
  },
});
