import axios, { AxiosRequestConfig } from "axios";

const DEFAULT_BACKEND_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3005"
    : "http://localhost:3005";

const OPENGATES_BACKEND_URL =
  process.env.VUE_APP_OPENGATES_BACKEND_URL || DEFAULT_BACKEND_URL;

const OPENGATES_BEARER_TOKEN = localStorage.getItem("token") || "";

const OPENGATES_REQUEST_HEADERS = {
  headers: {
    Authorization: `Bearer ${OPENGATES_BEARER_TOKEN}`,
  },
};

const AXIOS_REQUEST = (
  method: string,
  searchString: string,
  body?: any
): Promise<any> => {
  const config: AxiosRequestConfig = {
    ...OPENGATES_REQUEST_HEADERS,
  };
  if (method === "GET") {
    return axios.get(OPENGATES_BACKEND_URL + searchString, config);
  }
  if (method === "POST") {
    return axios.post(
      OPENGATES_BACKEND_URL + searchString,
      body ? body : {},
      config
    );
  }
  if (method === "PUT") {
    return axios.put(
      OPENGATES_BACKEND_URL + searchString,
      body ? body : {},
      config
    );
  }
  if (method === "DELETE") {
    config.data = body ? body : {};
    return axios.delete(OPENGATES_BACKEND_URL + searchString, config);
  }
  return Promise.reject(new Error("Unsupported method"));
};

export { AXIOS_REQUEST };
