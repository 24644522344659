import { createStore } from "vuex";
import talksModule from "./modules/talk";
import registerModule from "./modules/register";
import { ITalkItem, ITalkItemState } from "../interfaces/TalkItem";
import questionModule from "./modules/questions";
import ratingModule from "./modules/ratings";

export default createStore({
  state: <ITalkItemState>{},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    talksModule,
    registerModule,
    questionModule,
    ratingModule,
  },
});
