
import router from "../router";
import { IUser } from "../interfaces/User";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "HomeView",
  components: {},
  setup() {
    const store = useStore();
    const formData = ref<IUser>({
      id: null,
      name: "",
      email: "",
      company: "",
      interests: "",
    });

    const register = async () => {
      await store.dispatch("registerUser", formData.value);

      setTimeout(() => {
        const user = localStorage.getItem("User");
        if (user) {
          router.push({ name: "home" });
        }
      }, 500);
    };

    return {
      formData,
      register,
    };
  },
});
