import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import TalkItemList from "../views/TalkItemListView.vue";
import Login from "../views/LoginView.vue";
import AdminUI from "../views/AdminView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: TalkItemList,
  },
  {
    path: "/joined",
    name: "joined",
    component: () =>
      import(/* webpackChunkName: "joined" */ "../views/JoinedView.vue"),
  },
  {
    path: "/info",
    name: "info",
    component: () =>
      import(/* webpackChunkName: "info" */ "../views/InfoView.vue"),
  },
  {
    path: "/register",
    name: "login",
    component: Login,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminUI,
    // beforeEnter: requireAdminAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
