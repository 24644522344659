
import { useStore } from "vuex";
import { defineComponent, computed, onMounted } from "vue";
import TalkItem from "../components/talkItem/TalkItem.vue";
import OpenGatesHeader from "../components/header/OpenGatesHeader.vue";
import OpenGatesFooter from "../components/footer/OpenGatesFooter.vue";
import { IUser } from "../interfaces/User";
import { ITalkItem } from "../interfaces/TalkItem";

export default defineComponent({
  name: "HomeView",
  components: {
    TalkItem,
    OpenGatesFooter,
    OpenGatesHeader,
  },
  setup() {
    const store = useStore();
    const talks = computed(() => store.getters.getTalks);
    const myTalks = computed(() => store.getters.getMyTalks);
    const user: IUser = JSON.parse(localStorage.getItem("User") || "");

    function reloadPage() {
      location.reload();
    }

    setInterval(reloadPage, 300000);

    function compareTime(timeA: string, timeB: string) {
      const [hourA, minuteA] = timeA.split(":").map(Number);
      const [hourB, minuteB] = timeB.split(":").map(Number);

      if (hourA !== hourB) {
        return hourA - hourB;
      }

      return minuteA - minuteB;
    }

    onMounted(async () => {
      await store.dispatch("fetchTalks");
      await store.dispatch("fetchAttendedTalks", user.id);
    });

    const sortedTalks = computed(() => {
      return talks.value.slice().sort((talkA: ITalkItem, talkB: ITalkItem) => {
        const timeA = talkA.time;
        const timeB = talkB.time;
        return compareTime(timeA, timeB);
      });
    });

    return { sortedTalks, myTalks };
  },
});
