
import { defineComponent, ref, onMounted } from "vue";
import "./open-gates-header.scss";

export default defineComponent({
  name: "OpenGatesHeader",
  setup() {
    return {};
  },
});
